export default [
  {
    groups: [
      {
        title: 'Thông tin',
        attributes: [
          {
            prop: 'name',
            label: 'Họ tên',
            type: 'text'
          },
          {
            prop: 'email',
            label: 'Email',
            type: 'text'
          },
          {
            prop: 'phone',
            label: 'Điện thoại',
            type: 'text'
          },
          {
            prop: 'role_id',
            label: 'Phân quyền',
            type: 'select',
            options: [
              {
                title: 'Admin',
                value: 1
              },
              {
                title: 'Content',
                value: 2
              },
              {
                title: 'Order',
                value: 3
              },
              {
                title: 'Accountant',
                value: 4
              }
            ]
          },
          {
            prop: 'password',
            label: 'Mật khẩu',
            type: 'password'
          }
        ]
      }
    ]
  }
]
