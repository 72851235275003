<template lang="html">
  <div class="row">
    <div class="col-12">
      <form-card
        v-for="(group, index) of centerGroups"
        :key="index"
        :group="group"
        v-model="form"
      ></form-card>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormCard from 'src/components/UIComponents/FormCard.vue';
import { Message, Input, Button } from 'element-ui';
import dataFrom from './admin-form';


let _form = {}, firstGroups;

export default {

  components: {
    FormCard,
    ElInput: Input,
    ElButton: Button,
    Message
  },

  beforeCreate() {
    firstGroups = dataFrom[0].groups;

    firstGroups.forEach(group => {
      group.attributes.forEach(attr => {
        if (attr.multiple && _form[attr.prop]) {
          _form[attr.prop] = [];
        } else {
          _form[attr.prop] = '';
        }
        attr.value = _form[attr.prop];
      });
    });

    window.AP = this;
  },

  data() {
    return {
      centerGroups: firstGroups
    }
  },

  computed: {
    form: {
      get() {
        return this.$store.state.staffDetail
      },
      set(value) {

      }
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Chỉnh sửa Nhân viên');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
    let id = this.$route.params.id;
    this.$store.dispatch('fetchStaffDetail', id);
  },
  methods: {
    cancel() {
      this.$router.push({name: 'AllAdmins'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(function (result) {
        if (result) {
          self.$store.dispatch('updateStaff', self.form).then(res => {
            console.log(res);
            if (res.success) {
              Message({
                message: 'Cập nhật thành công',
                type: 'success'
              });
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  console.log(err);
                  console.log(res.errors[err]);
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        }
      })
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.$store.state.staffDetail = [];
  }
}
</script>
